<template>
  <v-container
    v-if="platform"
    fluid
    class="my-0 px-2 px-sm-4 px-md-6"
    style="max-width: 1200px; margin-top: -2rem"
  >
    <v-row class="pt-0">
      <v-col>
        <v-alert type="info" color="primary" outlined>
          Found a mistake? Please let us know at
          <a
            :href="
              'mailto:tasha@countermetrics.org?subject=Feedback%20on%20the%20COUNTER%20Registry%20entry%20for%20' +
              platform.name +
              (platform.contact && platform.contact.email
                ? '&cc=' + platform.contact.email
                : '')
            "
            >tasha@countermetrics.org</a
          >.
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="4">
          <v-card-text>
            <v-row no-gutters>
              <v-col class="px-4 py-2">
                <h2 class="text-h4">{{ platform.name }}</h2>
                <h3 v-if="platform.abbrev" class="text-h5">
                  {{ platform.abbrev }}
                </h3>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col class="px-4 py-2">
                <table class="text-subtitle-1">
                  <tr>
                    <th class="font-weight-light text-left pr-3">
                      {{ $t("title.contentProvider") }}:
                    </th>
                    <td class="font-weight-bold">
                      {{ platform.content_provider_name || "-" }}
                    </td>
                  </tr>
                  <tr>
                    <th class="font-weight-light text-left pr-3">
                      {{ $t("label.platform.hostType") }}:
                    </th>
                    <td class="font-weight-bold">
                      {{ hostTypes }}
                    </td>
                  </tr>
                </table>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="auto">
                <AddressWidget :source="platform" :elevation="0" />
              </v-col>
              <v-col cols="auto">
                <ContactWidget
                  v-if="platform.contact"
                  :contact="platform.contact"
                  :elevation="0"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <h3 class="pt-8 ml-2">{{ $t("title.currentReleases") }}</h3>
    <v-expansion-panels v-model="currentPanels" multiple>
      <SushiServiceSection
        :counter-version="release.number"
        :available-reports="release.report_types"
        :sushi-service="serviceForCounterRelease(release.number)"
        :reports="reportsForCounterRelease(release.number)"
        :audited="platform.audited"
        v-for="release in currentReleases"
        :key="release.pk"
      />
    </v-expansion-panels>

    <h3 class="pt-8 ml-2" v-if="inactiveReleases.length">
      {{ $t("title.inactiveReleases") }}
    </h3>
    <v-expansion-panels
      v-if="inactiveReleases.length"
      v-model="inactivePanels"
      multiple
    >
      <SushiServiceSection
        :counter-version="release.number"
        :available-reports="release.report_types"
        :sushi-service="serviceForCounterRelease(release.number)"
        :reports="reportsForCounterRelease(release.number)"
        :audited="platform.audited"
        v-for="release in inactiveReleases"
        :key="release.pk"
      />
    </v-expansion-panels>
  </v-container>
</template>

<script>
import CounterAPI from "../lib/api";
import ContactWidget from "./ContactWidget";
import AddressWidget from "./AddressWidget";
import SushiServiceSection from "./SushiServiceSection";

export default {
  name: "PlatformDetail",
  components: { SushiServiceSection, AddressWidget, ContactWidget },
  props: {
    platformId: { required: true, type: String },
  },

  data() {
    return {
      api: new CounterAPI(process.env.COUNTER_API_ROOT),
      loadingPlatform: false,
      loadingReports: false,
      platform: null,
      counterReleases: [],
      drawer: null,
      currentPanels: [],
      inactivePanels: [],
    };
  },

  computed: {
    reportsForCounterRelease() {
      return function (release) {
        return this.platform.reports.filter(
          (report) => report.counter_release === release
        );
      };
    },
    serviceForCounterRelease() {
      return function (release) {
        return this.platform.sushi_services.find(
          (ss) => ss.counter_release === release
        );
      };
    },
    loading() {
      return this.loadingReports || this.loadingPlatform;
    },
    currentReleases() {
      return this.counterReleases.filter((release) => release.current);
    },
    inactiveReleases() {
      return this.counterReleases.filter((release) => !release.current);
    },
    hostTypes() {
      return this.platform.host_types.map((item) => item.name).join(", ");
    },
  },

  methods: {
    async getPlatform() {
      this.loadingPlatform = true;
      try {
        const platform = await this.api.getOnePlatform(this.platformId);
        await this.api.platformAddAuditData(platform);
        this.platform = platform;
      } finally {
        this.loadingPlatform = false;
      }
    },
    async getReports() {
      this.loadingReports = true;
      try {
        this.counterReleases = await this.api.getReports();
        // sort higher numbers first
        this.counterReleases.sort((a, b) => b.number.localeCompare(a.number));
      } finally {
        this.loadingReports = false;
      }
    },
  },

  watch: {
    platformId: {
      immediate: true,
      handler() {
        this.getPlatform();
      },
    },
    loading() {
      if (!this.loading && this.platform) {
        // loading has finished, we select which panels to open by default
        let panels = [];
        this.currentReleases.forEach((release, idx) => {
          if (release.current) {
            panels.push(idx);
          }
        });
        this.currentPanels = panels;
      }
    },
  },

  async mounted() {
    await this.getReports();
  },
};
</script>

<style scoped>
address {
  font-style: normal;
}

.feedback-btn {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 7;
}
</style>
